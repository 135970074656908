@font-face {
    font-family: 'Lato';
    src: url('Lato-Regular.eot');
    src: local('Lato Regular'), local('Lato-Regular'),
        url('Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('Lato-Regular.woff2') format('woff2'),
        url('Lato-Regular.woff') format('woff'),
        url('Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Medium.eot');
    src: local('Lato Medium'), local('Lato-Medium'),
        url('Lato-Medium.eot?#iefix') format('embedded-opentype'),
        url('Lato-Medium.woff2') format('woff2'),
        url('Lato-Medium.woff') format('woff'),
        url('Lato-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Bold.eot');
    src: local('Lato Bold'), local('Lato-Bold'),
        url('Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('Lato-Bold.woff2') format('woff2'),
        url('Lato-Bold.woff') format('woff'),
        url('Lato-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
