/**
 * @license
 * MyFonts Webfont Build ID 4203354, 2021-10-29T10:28:52-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Gilroy-SemiBold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold/
 * 
 * Webfont: Gilroy-Bold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/bold/
 * 
 * 
 * Webfonts copyright: Copyright � 2016 by Radomir Tinkov. All rights reserved.
 * 
 * © 2021 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/40235a");
  
@font-face {
  font-family: "Gilroy-SemiBold";
  src: url('webFonts/GilroySemiBold/font.woff2') format('woff2'), url('webFonts/GilroySemiBold/font.woff') format('woff');
}
@font-face {
  font-family: "Gilroy-Bold";
  src: url('webFonts/GilroyBold/font.woff2') format('woff2'), url('webFonts/GilroyBold/font.woff') format('woff');
}
