.Header {
	width: 100%;
	left: 0;
	top: 0;
	position: fixed;
	z-index: 10;
	background: var(--e-background-1);
	&__logo {
		img {
			max-width: 100%;
		}
	}
}