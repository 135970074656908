.SignUp {
	background: var(--e-background-2);
	min-height: 100%;
	.signup-block {
		background: var(--white);
		border-radius: 10px;
		box-shadow: 0px 0px 30px rgba(3, 5, 10, 0.02);
		.sign-up-progress {
			position: relative;
			&::before {
				content: "";
				height: 2px;
				width: 100%;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				left: 0;
				background: var(--e-background-2);
				z-index: 0;
			}
			&__item {
				z-index: 1;
				background: var(--e-background-2);
				border-radius: 999px;
				line-height: 1;
				cursor: pointer;
				&:hover {
					background: var(--e-primary-opacity-hover);
				}
				&.current {
					cursor: default;
					background: var(--e-primary);
					svg > path {
						fill: var(--white);
					}
					& ~ .sign-up-progress__item {
						cursor: default;
						&:hover {
							background: var(--e-background-2);
						}
					}
				}
				svg {
					& > path {
						fill: var(--black);
					}
				}
			}
		}
		.agreement {
			font-size: .875rem;
		}
		.thank-you {
			background: var(--e-background-2);
			border-radius: 20px;
		}
		
	}
}