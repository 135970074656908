.Home {
	.main {
		position: relative;
		.main__title {
			position: relative;
			&:before {
				content: "";
				z-index: -1;
				position: absolute;
				left: -50%;
				top: -25%;
				width: 150%;
				height: 150%;
				background: radial-gradient(50% 50% at 50% 50%, rgba(58, 75, 222, 0.295) 0%, rgba(58, 75, 222, 0) 100%);
			}
		}
		&__illustration {
			@media(min-width: 992px) {
				position: absolute;
				right: -20%;
			}
			@media(min-width: 1200px) {
				position: static;
			}
		}
	}
	.advantages {
		&-inner {
			position: relative;
			&:before {
				content: "";
				z-index: -1;
				position: absolute;
				left: 0%;
				top: -25%;
				width: 100%;
				height: 100%;
				background: radial-gradient(50% 50% at 50% 50%, rgba(58, 222, 94, 0.295) 0%, rgba(58, 222, 94, 0) 100%);
			}
		}
	}
	.opportunities {
		&-item {
			&__icon {
				width: 2em;
				margin-top: 3px;
			}
		}
	}
	.tariffs-for-your-business {
		.areas {
			&-item {
				border-radius: 20px;
				overflow: hidden;
				&__heading {
					color: var(--white);
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}
		}
	}
}