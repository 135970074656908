.Input {
	&__hint-text {
		font-size: .875rem;
		color: var(--e-text-color);
	}
	input {
		&.error {
			border: 1px solid var(--e-input-error);
		}
	}
	&__error-text {
		font-size: .875rem;
		color: var(--e-input-error);
	}
}