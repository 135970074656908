html, body, #root, .App {
  height: 100%;
  position: relative;
}

body {
  overflow-x: hidden;
}

* {
  font-family: "Lato";
  font-weight: 400;
  box-sizing: border-box;
}

h1,h2,h3,h4,h5 {
  font-weight: 700;
  font-family: "Gilroy";
}

h3,h4,h5 {
  font-weight: 600;
}

p {
  font-size: 1.125rem;
  line-height: 190%;
}