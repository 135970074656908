.InputPhone {
	&.error {
		.react-tel-input input.form-control {
			border: 1px solid var(--e-input-error);
		}	
	}
	&__hint-text {
		font-size: .875rem;
		color: var(--e-text-color);
	}
	&__error-text {
		font-size: .875rem;
		color: var(--e-input-error);
	}
	.react-tel-input {
		font-family: inherit;
		font-size: inherit;
		input.form-control {
			line-height: inherit;
			height: inherit;
			width: 100%;
			border-radius: 5px;
			border: 1px solid var(--e-input-border-color);
			padding: .8rem 1rem .8rem 4rem;
			font-size: inherit;
		}
	}
	
}