.Tariffs {
	.change {
		border-radius: 10px;
		overflow: hidden;
		user-select: none;
		input {
			display: none;
			&:checked + label {
				background: var(--e-primary);
				color: var(--white);
				cursor: default;
			}
		}
		label {
			transition-duration: .3s;
			background: var(--e-background-2);
			color: var(--e-text-color);
			cursor: pointer;
		}
	}
	.tarriffs-container {
		.tariff-card {
			&-inner {
				background: var(--e-background-2);
				border-radius: 20px;
			}
			&__old-price {
				text-decoration: line-through;
			}
		}
	}
	.included-features {
		.features-list {
			list-style-position: inside;
			padding: 0;
		}
	}
}