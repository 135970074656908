.ForgetPassword {
	background: var(--e-background-2);
	min-height: 100%;
	.forget-password-block {
		background: var(--white);
		border-radius: 10px;
		box-shadow: 0px 0px 30px rgba(3, 5, 10, 0.02);
		.thank-you {
			background: var(--e-background-2);
			border-radius: 20px;
		}
	}
}